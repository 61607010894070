import { Tag } from '@packages/sk8/tag'
import { classMerge } from '@packages/sk8/utils'
import { AddonId, AddonName } from '@packages/types'
import React from 'react'

const addonNameById = {
  [AddonId.WhiteLabel]: { name: AddonName.WhiteLabel, className: 'bg-neutral-100 text-neutral-800' },
  [AddonId.ConfigurePriceQuote]: { name: AddonName.CPQ, className: 'bg-neutral-800 text-white' },
}

const SubscriptionTooltip = ({ addons }: { addons: AddonId[] }) => (
  <div>
    <h3 className="px-2 mb-2 font-medium">Addons:</h3>
    {addons.length === 0 && <div className="px-2 text-neutral-600">No subscribed addons</div>}
    {addons.length > 0 && (
      <div className="flex flex-col space-y-2 px-2">
        {addons.map(addonId => {
          const { name, className } = addonNameById[addonId]
          return <Tag className={classMerge('rounded w-fit', className)}>{name}</Tag>
        })}
      </div>
    )}
  </div>
)

export default SubscriptionTooltip
